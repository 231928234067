import styled from 'styled-components';

function getSize(props) {
    if(props.size === 'medium') {
        return 30;
    }
    if(props.size === 'small') {
        return 25;
    }


    return 35;
}

export const ToggleStyles = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    input {
        display: none;
        
        + label {
            flex: 0 0 ${props => getSize(props) * 2}px;
            height: ${props => getSize(props)}px;
            background: ${props => props.theme.color.divisor};
            display: block;
            position: relative;
            border-radius: 300px;
            transition: background .3s ease-out;
            cursor: pointer;

            &:after {
                content: '';
                width: ${props => getSize(props)}px;
                height: ${props => getSize(props)}px;
                background: ${props => props.theme.color.toggle};
                border-radius: 50%;
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                transition: transform .3s ease-out;
                box-shadow: 1px 1px 1px 0 rgba(0,0,0,.3);
            }

            @media ${props => props.theme.layout.media.mobile} {
                width: ${props => getSize(props) * 2}px;;
                height: ${props => getSize(props)}px;

                &:after {
                    width: ${props => getSize(props)}px;
                    height: ${props => getSize(props)}px;
                }
            }
        }
        &:checked {
            + label {
                background: ${props => props.theme.color.primaryColor};

                &:after {
                    transform: translate3d(100%, 0, 0);
                }
            }
        }
    }
    span {
        margin: 4px 0 8px;
        color: ${props => props.theme.color.textMain};
        flex: 1 1 100%;
        font-size: .6rem;
        text-transform: uppercase;
    }

    ${props => props.sideText && (`
        flex-wrap: nowrap;
        align-items: center;

        input {
            + label {
                order: 0;
            }
        }
        span {
            order: 1;
            margin: 0 0 0 15px;
            font-size: .8rem;
        }
    `)}
`