import styled from 'styled-components';

export const ActionsStyles = styled.div`
    display: flex;
    align-items: center;
`

export const NoItemsStyles = styled.div`
    text-align: center;
    padding: 25px;
    margin: 25px 0;
    color: ${props => props.theme.color.textMain};
    font-size: 1rem;
    font-weight: 300;
`

export const DecodedItemsStyles = styled.div`
    background: ${props => props.theme.color.baseGray};
    border: 1px solid ${props => props.theme.color.borderMain};
    border-radius: ${props => props.theme.layout.style.borderRadius};
    margin-top: 30px;

    h2, h3 {
        color: ${props => props.theme.color.textMain};
    }
    h2 {
        margin: 0;
    }
    h3 {
        margin: 3px 0 0 0;
        font-size: .9rem;
    }
    > div {
        padding: 0 15px;

        &:first-child {
            border-bottom: 1px solid ${props => props.theme.color.borderMain};
            padding: 15px;
        }

        ul {
            display: flex;
            flex-flow: row wrap;

            li {
                display: flex;
                align-items: center;
                flex-flow: row wrap;
                flex: 1 1 100%;
                max-width: 100%;
                padding: 15px 0;
                border-bottom: 1px solid ${props => props.theme.color.borderMain};

                &:last-child {
                    border-bottom: none;
                }

                div {
                    &:first-child {
                        flex: 1 1 calc(100% - 116px);
                        overflow: hidden;
                        position: relative;

                        &:after {
                            content: '';
                            background: ${props => props.theme.color.baseGradient};
                            position: absolute;
                            right: 0;
                            top: 0;
                            bottom: 0;
                            width: 50px;
                        }
                        a {
                            margin-bottom: 8px;
                            display: inline-block;
                        }
                        span {
                            display: block;
                            font-size: .6em;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                    }
                    &:last-child {
                        flex: 0 0 116px;
                        display: block;
                        width: 50px;
                        padding: 0 0 0 16px;
                    }
                }
            }
        }
    }
`