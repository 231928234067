import styled from "styled-components";

export const ToastStyles = styled.div`
  position: fixed;
  bottom: 16px;
  left: 16px;
  background: ${({ theme, type }) => theme.color[type]};
  color: ${({ theme }) => theme.color.textLight};
  padding: 16px;
  opacity: .5;
  transform: translate(0, calc( 100% + 16px ));
  box-sizing: border-box;
  transition-property: transform;
  transition-timing-function: ease-out;
  border-radius: ${({ theme }) => theme.layout.style.borderRadius};
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  max-width: 300px;
  width: calc( 100% - 32px );
  
  &.active {
      opacity: 1;
      transform: translate(0, 0);
  }
  .toast-icon {
      margin-right: 16px;
  }
  .toast-message {
      line-height: 1.6em;
  }
  .timer-bar {
      width: 100%;
      height: 5px;
      position: absolute;
      bottom: 0;
      left: 0;
      background: ${({ theme }) => theme.color.textLight};
      opacity: .6;
  }
  &.start-timer {
      .timer-bar {
          transition-property: width;
          transition-timing-function: ease-out;
          transition-duration: 3s;
          width: 0;
      }
  }
`