import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  body {
    color: ${({ theme }) => theme.color.textMain};
    background: ${({ theme }) => theme.color.background};
  }
  a {
    color: ${({ theme }) => theme.color.primaryColor};
    text-decoration: none;
  }
  @media (${({ theme }) => theme.layout.media.desktop}) {
    &:hover {
      // color: $link-color-hover;
    }
  }
`