import { rgba } from 'polished';

const layout = {
    media: {
        desktop: '(min-width: 1025px)',
        mobile: '(max-width: 1024px)'
    },
    font: {
        base: "'Roboto Mono', sans-serif"
    },
    style: {
        borderRadius: '4px'
    }
}

export const theme = {
    light: {
        color: {
            primaryColor: '#0971C5',
            primaryColorLighten: '#0c91f7',
            primaryColorDarken: '#06497c',
            baseGray: '#F0F0F0',
            get baseGradient() {
                return `linear-gradient(270deg, ${ rgba(this.baseGray, 100) } 30%, ${ rgba(this.baseGray, 0) } 100%);`;
            },
            textMain: '#707070',
            textLight: '#FFFFFF',
            borderMain: '#707070',
            background: '#FFFFFF',  
            divisor: '#BFBFBF',
            error: '#9F1717',
            errorAccent: '#F9D7D7',
            success: '#237721',
            successAccent: '#BCFBBB',
            info: '#085C99',
            infoAccent: '#085C99',
            toggle: '#F0F0F0'
        },
        common: {
            primaryColor: '#0971C5',
            textColor: '#707070',
            baseColor: '#707070'
        },
        layout: layout,
    },
    dark: {
        color: {
            primaryColor: '#0971C5',
            primaryColorLighten: '#0F8BE7',
            primaryColorDarken: '#002845',
            baseGray: '#262626',
            get baseGradient() {
                return `linear-gradient(270deg, ${ rgba(this.baseGray, 100) } 30%, ${ rgba(this.baseGray, 0) } 100%);`;
            },
            textMain: '#848484',
            textLight: '#FFFFFF',
            borderMain: '#848484',
            background: '#202020',
            divisor: '#505050',
            error: '#9F1717',
            errorAccent: '#F9D7D7',
            success: '#237721',
            successAccent: '#BCFBBB',
            info: '#085C99',
            infoAccent: '#085C99',
            toggle: '#c0c0c0'
        },
        common: {
            primaryColor: '#0971C5',
            textColor: '#000000',
        },
        layout: layout,
    }
}