import { Section } from 'components'
import { Link } from "react-router-dom";

import { useTheme } from 'styled-components';

export function Footer() {
    const theme = useTheme();

    const customStyles = `
        footer {
            text-align: center;

            ul {
                list-style: none;
                display: inline-block;
        
                li {
                    float: left;
                    padding: 0 15px;
                    position: relative;
        
                    &:before {
                        content: '';
                        position: absolute;
                        right: 0;
                        height: 100%;
                        width: 1px;
                        background: gray
                    }
        
                    &:last-child {
                        &:before {
                            content: none;
                        }
                    }
                }
            }

            @media ${theme.layout.media.mobile} {
                ul {
                    text-align: center;

                    li {
                        width: 100%;
                        margin: 10px 0;
                        
                        &:before {
                            content: none;
                        }
                    }
                }
            }
        }
    `

    return(
        <Section customStyles={customStyles}>
            <footer>
                <ul>
                    <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                    <li><Link to="/terms-conditions">Terms And Conditions</Link></li>
                    <li><Link to="/contact">Contact Us</Link></li>
                </ul>
            </footer>
        </Section>
    )
}