import { Fragment } from 'react';
import { useTheme } from 'styled-components';

import { Header, Footer, Decoder, History, Toast, Section, Icon } from '../components';
import { AdsManual } from '../components/common/Ads/AdsManual';

export function Home() {
    const theme = useTheme();

    const customBoxSection = `        
        .container {
            display: flex;
            flex-flow: row wrap;

            div {
                flex: 1 1 33%;
                text-align: center;

                @media ${theme.layout.media.mobile} {
                    flex: 1 1 100%;
                    text-align: center;
                    margin-top: 20px;

                    p {
                        max-width: 350px;
                        display: inline-block;
                    }
                }

                [data-elem="icon"] {
                    font-size: 4.5rem;
                }

                h3 {
                    font-weight: 500;
                    font-size: 1.2rem;
                }

                p {
                    padding: 0 20px;
                    line-height: 1.6rem;
                    font-size: 300;
                }
            }
        }
    `

    return(
        <Fragment>
            <Header />
            <Decoder />
            {/* <Media queries={{
                mobile: '(max-width: 768px)',
                desktop: '(min-width: 769px)'
            }}>
                {matches => (
                    <>
                        {matches.mobile && (
                            <Ads />
                        )}
                        {matches.desktop && (
                            <AdsWide />
                        )}
                    </>
                )}
            </Media> */}
            <AdsManual />
            <History />
            <Toast />
            <Section centerTitle limited content>
                <h2>What is Outlook Link Decoder?</h2>
                <p>This app is an easy option to extract the URLs from Outlook links, Live links, Hotmail links and any other Office Safelinks.
    Outlook uses Advanced Threat Protection (ATP) which encodes your links to make security checks, according to Microsoft.</p>
                <p>Since it is very common for ATP DNSs to be unreachable, outlook links not opening in chrome, or outlook links not working, sometimes when you click an email link you are redirected to an error page instead of the expected page. We give you the options to go directly to the page you want to visit, instead of being redirected by Microsoft’s servers.</p>
                <p>If you have privacy concerns related to the links you visit from your email, this app is also for you. Decoding and clicking the link gives you the chance to visit the original link you received in your email, without hitting Microsoft servers.</p>
                <p>Use this app if you are having problems with Office Safelinks anytime to go directly to your links.</p>
            </Section>
            <Section sectionColor="blue" centerTitle customStyles={customBoxSection} content>
                <h2>Why use Outlook Link Decoder?</h2>
                <div>
                    <Icon icon="browse_gallery" size={50} color="white"  />
                    <h3>Simple and easy</h3>
                    <p>Be able to follow your email links even if Microsoft servers are down</p>
                </div>
                <div>
                    <Icon icon="admin_panel_settings" size={50} color="white"  />
                    <h3>Privacy</h3>
                    <p>Go straight to your links, without hitting MS Servers</p>
                </div>
                <div>
                    <Icon icon="speaker_notes_off" size={50} color="white" />
                    <h3>No Logs</h3>
                    <p>We do not keep your data, all input information is stored in your device</p>
                </div>
            </Section>
            <Footer />
        </Fragment>
    )
}