import PropTypes from 'prop-types';
import { IconStyles } from './Icon.styles';

export function Icon({ 
    icon, 
    type = 'material-icons-outlined', 
    color, 
    background,
    size = 24,
    margins
}) {

    return(
            <IconStyles 
                className={type}
                color={color}
                background={background}
                size={size}
                margins={margins}
            >
                { icon }
            </IconStyles>
    )
}

Icon.propTypes = {
    icon: PropTypes.string.isRequired,
    type: PropTypes.string,
    color: PropTypes.string,
    background: PropTypes.string,
    size: PropTypes.number
}