import { AlertBoxStyles } from "./AlertBox.styles";
import { useSpring, animated } from 'react-spring';

import { Icon } from 'components';

export function AlertBox({children, type}) {

    const containerSpring = useSpring({
        from: {
            opacity: 0,
            marginTop: 0
        },
        to: {
            opacity: 1,
            marginTop: 15
        }
    });

    function GetIcon() {
        switch(type) {
            case 'error': 
                return (<Icon icon="warning_amber" />)
            default:
                return '';
        }
    }

    return (
        <AlertBoxStyles type={type}>
            <animated.p style={containerSpring}>
                <GetIcon />
                <span dangerouslySetInnerHTML={{ __html: children }}></span>
            </animated.p>
        </AlertBoxStyles>
    )
}