import { DecodedItemsStyles, ActionsStyles, NoItemsStyles } from './History.styles';

import { useContext, useRef } from "react";
import { useTheme } from 'styled-components';
import { useTrail, animated } from 'react-spring';

import { HistoryContext } from '../../contexts/HistoryContext';

import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useMatchSize } from '../../hooks/useMatchSize';

import { Toggle, Section, Button } from 'components';
import { ButtonRouded } from '../common/ButtonRounded/ButtonRounded';

export function History() {
    const history = useContext(HistoryContext);
    const theme = useTheme();
    const { actions, dispatch } = history;
    const { active, links } = history.state;
    const containerRef = useRef(null);
    const historyItemRef = useRef(null);

    const listTrail = useTrail(links.length, {
        from: {
            paddingTop: 0,
            opacity: 0
        },
        to: {
            paddingTop: 10,
            opacity: 1
        },
        config: {
            mass: 2,
            tension: 400,
        }
    })

    const { size } = useMatchSize(containerRef, historyItemRef)

    const { deleteItems } = useLocalStorage(true);

    function deleteHistory() {
        dispatch(actions.setLinks([]))
        deleteItems();
    }

    return (
        <Section customClass="section-history">
            <ActionsStyles>
                <Toggle name="save_history" label="History" activeStatus={active} onChange={() => dispatch(actions.toggleActive(!active))} />
                { active && links.length > 0 && (
                    <Button 
                        borderRadius="300px" 
                        size="small" 
                        fill="outline" 
                        color={{main: theme.color.error}}
                        onClick={() => deleteHistory()}
                        icon="delete_outline"
                        margins="0 0 0 auto"
                    >
                        Clear
                    </Button>
                )}
            </ActionsStyles>
            { links && active && (
                <DecodedItemsStyles className="decoded-items-container">
                    <div>
                        <h2>Decoded Items</h2>
                        <h3>Items are stored only on this device</h3>
                    </div>
                    <div>
                        {links.length === 0 && active && (
                            <NoItemsStyles>You have no decoded links</NoItemsStyles>
                        )}
                        { links.length > 0 && (<ul>
                            { listTrail.map((spring, index) => {
                                const {title, url, id} = links[index];
                                
                                return(
                                    <animated.li key={index} style={{...spring}}>
                                        <div className="history-item-content" style={{ width: size }}>
                                            <a href={url} target="_blank" rel="noreferrer noopener">
                                                { title }
                                            </a>
                                            <span>
                                                { url }
                                            </span>
                                        </div>
                                        <div className="history-item-actions" ref={historyItemRef}>
                                            <ButtonRouded 
                                                icon="open_in_new" 
                                                color={theme.color.primaryColor} 
                                                onClick={() => window.open(url, '_blank')}
                                            />
                                            <ButtonRouded 
                                                icon="delete_outline" 
                                                color={theme.color.error} 
                                                onClick={() => dispatch(actions.deleteLink(id)) }
                                            />
                                        </div>
                                    </animated.li>
                                )
                            })}
                        </ul>)}
                    </div>
                </DecodedItemsStyles>
            ) }
        </Section>
    )
}