import { useReducer, createContext } from 'react';

const ToastContext = createContext();

const initialState = {
    message: null,
    type: 'info'
}

const actionTypes = {
    setMessage: 'SET_MESSAGE',
}

export const actions = {
    setMessage: ( payload ) => ({
        type: 'SET_MESSAGE',
        data: {
            message: payload.message,
            type: payload.type || 'info'
        }
    })
}

function ToastReducer(state, action){
    switch(action.type){
        case actionTypes.setMessage:
            return { ...state, message: action.data.message, type: action.data.type }

        default:
            return state;
    }
}

function ToastContextProvider({ children }){
    const [state, dispatch] = useReducer(ToastReducer, initialState);

    return <ToastContext.Provider value={{ state, dispatch, actions }}> { children } </ToastContext.Provider>
}

export { ToastContext, ToastContextProvider };