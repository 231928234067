// import "./App.scss";
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { GlobalStyle } from './globalStyles';
import { Contact, Home, PrivacyPolicy, TermsAndConditions } from './pages'

import { HistoryContextProvider } from './contexts/HistoryContext';
import { ToastContextProvider } from './contexts/ToastContext';

import { ThemeProvider } from 'styled-components';
import { theme } from './theme';

import { useMatchMedia } from 'hooks/useMatchMedia';

function App() {
	const media = useMatchMedia();

	return (
		<ThemeProvider theme={theme[media]}>
			<GlobalStyle />
			<HistoryContextProvider>
				<ToastContextProvider>
					<BrowserRouter>
						<Routes>
							<Route path="/" element={<Home />} />
							<Route path="/privacy-policy" element={<PrivacyPolicy />} />
							<Route path="/terms-conditions" element={<TermsAndConditions />} />
							<Route path="/contact" element={<Contact />} />
						</Routes>
					</BrowserRouter>
				</ToastContextProvider>
			</HistoryContextProvider>
		</ThemeProvider>
	);
}

export default App;
