import styled from 'styled-components';

export const PageWrapperStyles = styled.div`
    h1 {
        font-size: 2rem;
        font-weight: 400;
        color: ${(props) => props.theme.common.primaryColor};
        margin: 25px 0 20px;
    }

    ${(props) => props.contentPage && `
        h1 {
            text-align: center;
        }
        ul {
            list-style: circle;
            padding: 20px 30px;
        }
    `}

    form {
        width: 100%;
        text-align: center;

        label {
            width: 100%;
            display: block;
            text-align: left;
            font-size: 12px;
            text-transform: uppercase;
        }

        input, textarea {
            width: 100%;
            display: block;
        }

        button {
            float: right;
            font-size: 14px;
            padding: 10px 20px;
        }
    }
`