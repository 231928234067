export const customStyles = `
    .container {
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        max-width: 850px;

        > form {
            display: flex;
            align-items: center;
            justify-content: center;

            > input {
                border-radius: 4px 0 0 4px;
            }
        }

        form {
            flex: 1 1 100%;

            input {
                width: 100%;
            }
        }
    }
`
