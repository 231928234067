import { useEffect, useState } from 'react';

function getSize(ref, subtract) {
    if(ref) {
        if(subtract) {
            return ref.clientWidth - subtract.clientWidth;
        }
            
        return ref.clientWidth;
    }

    return 0;
}

/**
 * Match the size of first ref with second ref
 * @param {any} ref
 * @param {any} match
 */
export function useMatchSize(ref, subtract) {
    const [size, setSize] = useState();

    useEffect(() => {
        let mounted = true;

        const useMatchSizeListener = () => {
            setSize(getSize(ref.current, subtract.current));
        }

        window.addEventListener('resize', useMatchSizeListener)

        return () => {
            mounted = false;

            if(!mounted) {
                window.removeEventListener('resize', useMatchSizeListener);
            }
        }
    }, [ref, subtract])

    useEffect(() => {

        setSize(getSize());

    }, [ref])

    return { size };
}