import { useEffect, useState } from 'react';

export function useMatchMedia() {
    const [media, setMedia] = useState('light');

    useEffect(() => {
        if(window.matchMedia('(prefers-color-scheme: dark)').matches) {
            setMedia('dark');
        }

        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
            setMedia(e.matches ? "dark" : "light");
        });
    }, [])

    return media;
}