import styled from 'styled-components';
import { Icon } from '../Icon/Icon';

const StButton = styled.button`
  display: inline-flex;
  border-radius: 100%;
  cursor: pointer;
  padding: 6px;
  font-size: 6px;
  margin-left: 10px;
  border-style: solid;

  ${({ primary, color }) => primary && (`
    background-color: ${color ?? 'gray'};
    border-color: ${color ?? 'gray'};
    color: white;
  `)}
  ${({ primary, color }) => !primary && (`
    border-color: ${color ?? 'gray'};
    color: ${color ?? 'gray'};
  `)}
`

export const ButtonRouded = ({
  icon,
  color,
  primary = true,
  onClick
}) => {
  return (
    <StButton color={color} primary={primary} onClick={() => onClick &&  onClick()}>
      <Icon icon={icon} />
    </StButton>
  )
}