import styled from 'styled-components';

export const SectionStyles = styled.section.attrs(props => ({
    className: [props.customClass]
}))`

    display: inline-block;
    width: 100%;
    // color: #707070;
    color: var(--color-base);

    ${(props) => {
        if(props.sectionColor === 'gray') {
            return `
                background: ${props.theme.color.baseGray};
                color: #606060;
            `
        }
        if(props.sectionColor === 'blue') {
            return `
                background: ${props.theme.common.primaryColor};
                color: white;
            `
        }

        return ``
    }}}

    ${props => props.$borders && `
        border-top: 1px solid ${props.$borders};
        border-bottom: 1px solid ${props.$borders};
    `}

    .container {
        display: block;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        padding: ${(props) => props.paddingY}px 24px;
        ${(props) => props.limited && `
            max-width: 850px;
        `}

        h2 {
            font-weight: 300;
            flex: 1 1 100%;
            font-size: 1.5rem;
        }

        h3 {
            font-weight: 400;
        }

        p {
            line-height: 32px;
        }
    }

    ${(props) => props.centerTitle && `
        h2 {
            text-align: center;
        }
    `}
    
    ${(props) => props.$content && `
        .container {
            h2 {
                margin: 20px 0 40px 0;
            }
        }
    `}

    ${(props) => props.$customStyles && props.$customStyles}
`