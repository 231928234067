import styled from 'styled-components';

export const IconStyles = styled.span.attrs(props => ({
    'data-elem': 'icon'
}))`
    background: ${props => props.background ?? 'transparent'};
    color: ${props => props.color ?? 'inherit'};
    font-size: ${props => props.size}px;

    ${props => props.margins && (`
        margin: ${props.margins}
    `)}
`