import styled from 'styled-components';

export const DecodedBoxStyles = styled.div`
    width: 100%;

    > div {
        > div {
            border: 1px solid ${props => props.theme.color.borderMain};
            border-radius: ${props => props.theme.layout.style.borderRadius};
            padding: 15px;
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            justify-content: space-between;

            h3 {
                margin: 0 0 15px 0;
                text-transform: uppercase;
                font-weight: 300;
                font-size: .8rem;
                letter-spacing: 0.2em;
            }
            [data-elem="actions"] {
                flex: 0 0 auto;
                display: flex;

                li {
                    margin-right: 15px;
                    cursor: pointer;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
            input {
                flex: 1 1 100%;
            }
        }
    }
`