import { useState } from 'react';

import { Header, Footer, Section, PageWrapper, FormWrapper } from 'components';

export function Contact() {
    const [formFields, setFormFields] = useState({ name: '', email: '', message: ''});
    const [submitStatus, setSubmitStatus] = useState(null);

    const handleChange = (e) => setFormFields({...formFields, [e.target.name]: e.target.value })

    async function handleSubmit(e) {
        e.preventDefault();

        await fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": e.target.getAttribute('name'), ...formFields })
          })
            .then(res => {
                if(res.ok) {
                    setSubmitStatus('success')

                    return;
                }
                
                setSubmitStatus('error')

                return;
            })
            .catch(error => console.log(error));
    }

    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
    }

    return(
        <PageWrapper>
            <Header />
            <Section limited>
                <h1>Contact Us</h1>
                {submitStatus && 
                    <div>
                        {submitStatus === 'error' && (
                            <p>It was not possible to send your message</p>
                        )}
                        {submitStatus === 'success' && (
                            <p>Your message has been sent</p>
                        )}
                    </div>
                }
                <FormWrapper name="contact" onSubmit={handleSubmit} netlify>
                    <input type="hidden" name="form-name" value="contact" />
                    <p>
                        <label>
                            Your Name:
                        </label>
                        <input type="text" name="name" value={formFields.name} onChange={handleChange} required />
                    </p>
                    <p>
                        <label>
                            Your Email:
                        </label>
                        <input type="email" name="email" value={formFields.email} onChange={handleChange} required />
                    </p>
                    <p>
                        <label>
                            Message: 
                        </label>
                        <textarea rows="5" name="message" value={formFields.message} onChange={handleChange} required />
                    </p>
                    <p>
                        <button type="submit" className="button button-primary">
                            Send
                        </button>
                    </p>
                </FormWrapper>
            </Section>
            <Footer />
        </PageWrapper>
    )
}