import styled from 'styled-components';

export const FormWrapperStyles = styled.form.attrs(props => ({
    method: props.method,
    name: props.name,
    onSubmit: props.onSubmit,
    'data-netlify': props.netlify
}))`
    input {
        flex: 1 1 100%;
        height: 50px;
        padding: 0 12px;
        font-size: 1em;
        border: 1px solid ${props => props.theme.common.borderMain};
        border-radius: ${props => props.theme.layout.style.borderRadius};
        font-family: ${props => props.theme.layout.font.base}
    }

    textarea {
        border: 1px solid ${props => props.theme.common.borderMain};
        border-radius: ${props => props.theme.layout.style.borderRadius};
        font-size: 1em;
        font-family: ${props => props.theme.layout.font.base}
        padding: 15px;
    }

    input, textarea {
        color: ${props => props.theme.color.textMain};
        background: ${props => props.theme.color.background}
    }

    ${props => props.customStyles && props.customStyles}
`