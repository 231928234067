import { Link } from 'react-router-dom';

import { HeaderStyles } from './Header.styles';

import { ReactComponent as Logo} from '../../assets/svg/logo.svg';
 
export function Header() {
    return (
        <HeaderStyles>
            <div className="container">
                <div className="logo">
                    <Link to="/">
                        <Logo />
                    </Link>
                </div>
            </div>
        </HeaderStyles>
    )
}