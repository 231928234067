import PropTypes from 'prop-types';
import { ButtonStyles } from './Button.styles';

import { Icon } from 'components';

export function Button({ 
    children, 
    onClick, 
    borderRadius, 
    height, 
    icon, 
    iconPosition = 'left', 
    size, 
    fill = 'fill',
    color,
    margins,
}) {

    return(
        <ButtonStyles 
            onClick={(e) => onClick(e)} 
            borderRadius={borderRadius} 
            height={height} 
            size={size}
            fill={fill}
            color={color}
            margins={margins}
        >
            {icon && (
                <Icon icon={icon} margins="0 5px 0 0" />
            )}
            <span>{children}</span>
            {icon && iconPosition === 'right' && (
                <Icon icon={icon} margins="0 0 0 5px" />
            )}
        </ButtonStyles>
    )
}

Button.propTypes = {
    onClick: PropTypes.func,
    borderRadius: PropTypes.string,
    height: PropTypes.number,
    icon: PropTypes.string,
    iconPosition: PropTypes.string,
    size: PropTypes.string,
    fill: PropTypes.string,
    color: PropTypes.shape({
        main: PropTypes.string,
        hover: PropTypes.string
    }),
    margins: PropTypes.string,
}