import styled from 'styled-components';

function getColor(props) {

    if(props.color) {
        return {
            color: props.color.main,
            hover: props.color.hover ?? props.color.main 
        }
    }
    
    return {
        color: props.theme.color.primaryColor,
        hover: props.theme.color.primaryColorLighten
    }
}

export const ButtonStyles = styled.button`
    padding: 10px 15px;
    text-transform: uppercase;
    color: ${props => props.theme.color.baseGray};
    border-radius: ${ props => props.borderRadius ?? `${props.theme.layout.style.borderRadius}px` };
    font-weight: 500;
    font-size: .9rem;
    height: ${props => props.height ? `${props.height}px` : 'auto'};
    cursor: pointer;
    transition: background .2s ease-out;
    background: ${ props => getColor(props).color };
    border: 1px solid transparent;
    display: flex;
    align-items: center;

    &:hover {
        background: ${ props => props.theme.color.primaryColorLighten};
    }

    ${props => props.margins && (`
        margin: ${props.margins};
    `)}

    ${props => props.fill === 'outline' && (`
        background: transparent;
        border: 1px solid ${ getColor(props).color };
        color: ${ getColor(props).color };

        &:hover {
            background: ${ getColor(props).hover };
            border: 1px solid ${ getColor(props).hover };
            color: #FFFFFF;
        }
    `)}


    ${props => props.size === 'small' && (`
        font-size: .8rem
    `)}
`;