import styled from "styled-components";

export const AdsStyles = styled.div`
    margin: 15px 0;
    display: flex;
    width: 100%;
    justify-content: center;
    
    span {
        background: #F0F0F0;
        flex: 0 1 auto;
        display: block;
    }
`;

export const AdsWideStyles = styled.div`
    margin: 15px 0;
    display: flex;
    width: 100%;
    justify-content: center;
    
    span {
        background: #F0F0F0;
        flex: 0 1 auto;
        display: block;
    }
`;