import styled from 'styled-components';

export const AlertBoxStyles = styled.div`
    width: 100%;

    p {
        color: white;
        margin: 0;
        padding: 15px;
        display: flex;
        border-radius: ${props => props.theme.layout.style.borderRadius};
        color: ${props => props.theme.color.textMain};
        border: 1px solid ${props => props.theme.color.borderMain};

        ${props => props.type === 'error' && `
            color: ${props.theme.color.error};
            border: 1px solid ${props.theme.color.error};
            background: ${props.theme.color.errorAccent};
        `}

        [data-elem="icon"] {
            flex: 0 0 30px;
            font-size: 30px;
            margin-right: 15px;
        }
    }
`