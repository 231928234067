import { SectionStyles } from './Sections.styles';

export function Section({
    children, 
    container = true, 
    sectionColor = null, 
    customClass, 
    customStyles,
    centerTitle = false,
    limited = false,
    content = false,
    paddingY = 24,
    borders = false
}) {

    return(
        <SectionStyles 
            customClass={customClass} 
            $customStyles={customStyles} 
            sectionColor={sectionColor} 
            centerTitle={centerTitle} 
            limited={limited}
            $content={content}
            paddingY={paddingY}
            $borders={borders}
        >
            { container ?
                    <div className="container">
                        {children}
                    </div>
                :
                    children
            }
        </SectionStyles>
    )
}