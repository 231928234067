import { useState, useEffect, useContext } from 'react';

import { useGAEvent } from './useGAEvent';

import { randomString } from '../components/helpers';

import { HistoryContext } from '../contexts/HistoryContext';

export default function useDecoder() {
    const [link, setLink] = useState(null);
    const [decodedLink, setDecodedLink] = useState(null);
    const [error, setError] = useState(null);

    const history = useContext(HistoryContext);
    const { actions, dispatch } = history;
    const { active } = history.state;

    const { dispatch: gaDispatch } = useGAEvent();

    useEffect(() => {
        if(link === null) return;

        if( link.length === 0 ) {
            setError('Please provide a link to decode');
            setDecodedLink(null);

            return;
        }

        const urlParts = link.split("?")[1];

        if(!urlParts) {
            setDecodedLink(null);
            setError(`"<strong>${link}</strong>" is not a valid link`);
            gaDispatch('event', 'decode_fail', { error: 'invalid URL' });

            return;
        }

        const params = urlParts.split("&");
        let targetUrl = false;

        for(let n=0; n<params.length; n++)
        {
            let currentParam = params[n].split("=");
            if(currentParam[0] === "url") {
                targetUrl = currentParam[1];
            }
        }
        
        if(targetUrl) {
            const URLData =  new URL(decodeURIComponent(targetUrl));
            setDecodedLink({ id: randomString(), title: URLData.host, url: URLData.href });
            gaDispatch('event', 'decode_success');

            setError(null);
        }else {
            setDecodedLink(null);
            setError(`Please provide a valid Outlook link`);
            gaDispatch('event', 'decode_fail', { error: 'Not Outlook URL' });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [link])

    useEffect(() => {
        if(active && decodedLink) {
            dispatch(actions.addLink(decodedLink));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [decodedLink, active, actions, dispatch])

    return [
        setLink,
        decodedLink,
        error
    ]
}