import { useContext } from 'react';
import { DecodedBoxStyles } from "./DecodedBox.styles";
import { useSpring, animated } from 'react-spring';

import { FormWrapper, Icon } from 'components';

import { ToastContext } from '../../contexts/ToastContext';


export function DecodedBox({ decodedLink }) {
    const toast = useContext(ToastContext);
    const { actions, dispatch } = toast;

    const handleCopy = () => {
        dispatch(actions.setMessage({ message: 'Copied to clipboard!', type: 'success'}))
        navigator.clipboard.writeText(decodedLink.url)
    }

    const containerSpring = useSpring({
        from: {
            opacity: 0,
            marginTop: 0
        },
        to: {
            opacity: 1,
            marginTop: 15
        }
    });

    return (
        <DecodedBoxStyles>
            <animated.div style={containerSpring}>
                <div>
                    <h3>Your decoded link:</h3>
                    <ul data-elem="actions">
                        <li 
                            onClick={() => window.open(
                                decodedLink.url,
                                '_blank'
                            )}
                        >
                            <Icon icon="open_in_new" />
                        </li>
                        <li onClick={() => handleCopy()}>
                            <Icon icon="content_copy" />
                        </li>
                    </ul>
                    <FormWrapper onSubmit={(e) => e.preventDefault()}>
                        <input type="text" value={decodedLink.url}></input>
                    </FormWrapper>
                </div>
            </animated.div>
        </DecodedBoxStyles>
    )
}