import Media from 'react-media';
import styled from 'styled-components';
import Ads from '../../../assets/images/coindog-ads.jpg';
import AdsMobile from '../../../assets/images/coindog-ads-mobile.jpg';

const StyledBlock = styled.div`
  width: 100%;
  text-align: center;
  margin: 20px 0;

  a {
    display: inline-block;
    border-radius: 3px;
    overflow: hidden;
    border: 1px solid ${({theme}) => theme.color.divisor};

    img {
      display: block;
    }
  }
`;

function AdsWrapper() {
  return (
    <Media queries={{
      mobile: '(max-width: 768px)',
      desktop: '(min-width: 769px)'
    }}>
        {matches => (
            <>
                {matches.mobile && (
                    <img src={AdsMobile} alt="Coin Dog Ads link" />
                )}
                {matches.desktop && (
                    <img src={Ads} alt="Coin Dog Ads link" />
                )}
            </>
        )}
    </Media>
  )
}

export const AdsManual = () => {
  return (
    <StyledBlock>
      <a href="https://coindog.co/?utm_campaign=general_traffic&utm_medium=link&utm_source=outlooklinkdecoder&mtm_campaign=int-network&mtm_source=outlooklinkdecoder" target="_blank" rel="noreferrer">
        <AdsWrapper />
      </a>
    </StyledBlock>
  )
}