import { useReducer, createContext } from 'react';

const HistoryContext = createContext();

const initialState = {
    links: [],
    loading: true,
    active: true
}

const actionTypes = {
    addLink: 'ADD_LINK',
    setLinks: 'SET_LINKS',
    deleteLink: 'DELETE_LINK',
    setLoading: 'SET_LOADING',
    toggleActive: 'TOGGLE_ACTIVE'
}

export const actions = {
    addLink: (payload) => ({
        type: 'ADD_LINK',
        payload
    }),
    setLinks: (payload) => ({
        type: 'SET_LINKS',
        payload
    }),
    deleteLink: (payload) => ({
        type: 'DELETE_LINK',
        payload
    }),
    setLoading: (payload) => ({
        type: 'SET_LOADING',
        payload
    }),
    toggleActive: (payload) => ({
        type: 'TOGGLE_ACTIVE',
        payload
    })

}

function HistoryReducer(state, action){
    switch(action.type){
        case actionTypes.addLink:
            return { ...state, links: [{ id: action.payload.id, title: action.payload.title, url: action.payload.url }, ...state.links] };

        case actionTypes.setLinks:
            return { ...state, links: action.payload, loading: false };

        case actionTypes.deleteLink:
            return { ...state, links: state.links.filter((link) => link.id !== action.payload) };

        case actionTypes.setLoading: 
            return { ...state, loading: action.payload }
        
        case actionTypes.toggleActive:
            return { ...state, active: action.payload }

        default:
            return state;
    }
}

function HistoryContextProvider({ children }){
    const [state, dispatch] = useReducer(HistoryReducer, initialState);

    return <HistoryContext.Provider value={{ state, dispatch, actions }}> { children } </HistoryContext.Provider>
}

export { HistoryContext, HistoryContextProvider };