import { useContext, useState, useEffect, useRef } from 'react';
import { ToastStyles } from './Toast.styles';
import { ToastContext } from '../../contexts/ToastContext';

const ANIMATION_TIMER = '.3s';
const ANIMATION_TIMER_MS = 300;
const TIMER = 3000;

export function Toast() {
    const toast = useContext(ToastContext);
    const { actions, dispatch, state: toastState } = toast;
    const toastRef = useRef(null);
    const [show, setShow] = useState(false);

    useEffect(() => {   
        toastRef.current.classList.remove('start-timer');

        const timeout = setTimeout(() => {
            toastRef.current.classList.remove('start-timer');
            setShow(false)
        }, TIMER)
        
        if(toastState.message) {
            setTimeout(() => {
                toastRef.current.classList.add('start-timer');
            }, 10)
            setShow(true);
        }
        
        return () => {
            clearTimeout(timeout);
        }
    }, [toastState.message, actions, dispatch])

    useEffect(() => {
        let clearMessage;
        if(show) {
            toastRef.current.classList.add('active')
        } else {
            toastRef.current.classList.remove('active')
            clearMessage = setTimeout(() => {
                dispatch(actions.setMessage({ message: null }));
            }, ANIMATION_TIMER_MS);
        }

        return () => clearTimeout(clearMessage);
    }, [show, actions, dispatch])

    return (
        <ToastStyles className="toast" data-type={toastState.type} type={toastState.type || 'info'} ref={toastRef} style={{ transitionDuration: ANIMATION_TIMER }}>
            <span className="toast-icon">
                {toastState.type === 'info' && (
                    <span className="material-icons-outlined info"> info </span>
                )}
                {toastState.type === 'success' && (
                    <span className="material-icons-outlined success"> check_circle </span>
                )}
                {toastState.type === 'error' && (
                    <span className="material-icons-outlined error"> error_outline </span>
                )}
            </span>
            <span className="toast-message">
                {toastState.message}
            </span>

            <span className="timer-bar"></span>
        </ToastStyles>
    )
}