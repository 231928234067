import styled from 'styled-components';

export const HeaderStyles = styled.header`
    padding: 30px 0;
        
    .logo {
        max-width: 200px;
        display: block;
        margin: 0 auto;

        svg {
            width: 100%;
            height: auto;
        }
    }
`