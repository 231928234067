import { useEffect, useContext, useState } from 'react';

import { HistoryContext } from '../contexts/HistoryContext';

const ITEM_NAME = 'OutlookLinkDecoder';

export function useLocalStorage(load) {
    const history = useContext(HistoryContext);
    const { actions, dispatch } = history;
    const { links, active } = history.state;

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if(links && links.length > 0 && active && !loaded) {
            localStorage.setItem(ITEM_NAME, JSON.stringify(links));
            setLoaded(true);
        }
        if(links && active && loaded) {
            localStorage.setItem(ITEM_NAME, JSON.stringify(links));
        }
    }, [links, active, loaded])

    useEffect(() => {
        if(load && active) {
            const localItems = localStorage.getItem(ITEM_NAME);

            if(localItems && localItems.length > 0) {
                dispatch(actions.setLinks( JSON.parse(localItems) ))
            }
        }
    }, [actions, active, dispatch, load])

    function deleteItems() {
        localStorage.removeItem(ITEM_NAME)
    }

    return {
        deleteItems
    }
}