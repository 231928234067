import { ToggleStyles } from "./Toggle.styles"

export function Toggle({ name, label, activeStatus, onChange, size = 'medium', sideText = true }) {

    return (
        <ToggleStyles size={size} sideText={sideText}>
            { label && (
                <span>{label}</span>
            ) }
            <input name={name} id={name} type="checkbox" onChange={onChange} checked={activeStatus} />
            <label htmlFor={name}></label>
        </ToggleStyles>
    )
}