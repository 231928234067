import { useRef, useEffect } from 'react';
import useDecoder from '../../hooks/useDecoder';
import { useTheme } from 'styled-components';

import { Section, FormWrapper, AlertBox, DecodedBox } from 'components';

import { customStyles } from './Decoder.styles';
import { Button } from '../common/Button/Button';

export function Decoder() {
    const theme = useTheme();

    const [setLink, decodedLink, decoderError] = useDecoder();
    const decodeRef = useRef(null);

    const enterDecode = (e) => {
        if(e.code === 'Enter') {
            setLink(decodeRef.current.value)
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', enterDecode)

        return () => {
            window.removeEventListener('keydown', enterDecode);
        }
    })

    return (
        <Section customClass="section-decoder" sectionColor="gray" customStyles={customStyles} borders={theme.color.borderMain}>
            <FormWrapper className="action" onSubmit={(e) => e.preventDefault()}>
                <input ref={decodeRef} type="text" placeholder="Paste your link here"></input>
                <Button onClick={() => setLink(decodeRef.current.value)} borderRadius='0 4px 4px 0' height={50}>Decode</Button>
            </FormWrapper>
            { decoderError && (
                <AlertBox type='error'>{decoderError}</AlertBox>
            )}
            { decodedLink && (
                <DecodedBox decodedLink={decodedLink} />
            )}
        </Section>
    )
}